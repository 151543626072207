.footer{
    //min-height: 40vh;
    display: flex;
    flex-direction: column;
    //padding: 60px 235px;
    background-color: $brown;

    @media(max-width:1440px){
        min-height: auto;
    }
    &-group-container{
        display: flex;
        justify-content: space-around;
        flex: 1;
        margin-top: 30px;

        @media(max-width:1440px){
            margin-top:15px;
        }
    }
    &-group{

        &-title{
            margin-bottom: 20px;
            span{
                font-size: 14px;
                @media(max-width:1440px){
                    font-size: 12px;
                }
            }
        }

        &-item{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            span{
                font-size: 12px;
                @media(max-width:1440px){
                    font-size: 9px;
                }
                @media(max-width:500px){
                    font-size: 7px;
                }
            }
            svg{
                margin-right: 10px;
                @media(max-width:500px){
                    font-size: 9px;
                }
            }

        }
    }

    &-copy{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:10px 12%;
        border-top: 1px solid #EFEDEA;

        @media(max-width:500px){
            height: auto;
            padding: 10px 6%;
        }
        span{
            font-size: 12px;
            text-transform: none;
            line-height: 10px;
            letter-spacing: normal;
            @media(max-width:500px){
                font-size: 9px;
            }
        }
    }
}