.first-screen {
    .preview{
        background-color: $black;
    }
  .first-screen-opacity {
    position: absolute;
    text-align: center;
    animation: opacity 2s ease-in-out;
    width:100%;
    height:100%;
    background: rgba(114, 172, 226, 0.600);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      white-space: pre;
      color: white;
      margin-top: 20px;
      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
  }

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
