.second-screen {
  display: flex;
  align-items: center;
  background-color: $black;
  color: $light-white;
  min-height: 100vh;

  .preview{
    background-color: $light-white;
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
      width: 800px;

      @media (max-width: 1600px) {
        width: 450px;
      }

      @media (max-width: 1024px) {
        display: none;
      }

      @media (max-width: 500px) {
        display: none;
      }
    }
    .second-screen-title {
      padding-left: 60px;
      @media (max-width: 1024px) {
        padding-left: 0px;
      }
      flex: 1;
      h3 {
        white-space: pre;
        @media (max-width: 1440px) {
          font-size:90px;
        }
        @media (max-width: 1024px) {
            font-size: 60px; 
            text-align: center;
        }
        @media (max-width: 600px) {
            font-size: 30px;
            text-align: center;
          }
      }
      span{
        display:block;
        margin-bottom:10px;
        @media (max-width: 1024px) {
            margin-bottom: 20px;
            display: block;
            text-align: center;
          }
      }
    }
  }

  .second-screen-items {
      //overflow: hidden;
    margin: 50px 0px;
    :nth-child(2) {
      border-top: 0px !important;
      border-bottom: 0px !important;
    }
    :first-child div{
        &::after{
            content: '';
            height: 1px;
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            background-color: white;
            @media (max-width:1024px){
                width: 100%;
            }
        }
        &::before{
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            width: 100.8%;
            left: 0;
            background-color: white;
            @media(max-width:1440px){
                width: 100.4%;
            }
            @media (max-width:1024px){
                width: 100%;
            }
        }
    }
    :nth-child(2) div{
        
        &::before{
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            width: 102.5%;
            left: 0;
            background-color: white;
            @media(max-width:1440px){
                width: 103.5%;
            }
            @media (max-width:1024px){
                width: 100%;
            }
        }
    }
    :nth-child(3) div{
        
        &::before{
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            width: 106%;
            left: 0;
            background-color: white;
            @media(max-width:1440px){
                width: 110.1%;
            }
            @media (max-width:1024px){
                width: 100%;
            }
            
        }
    }
    .second-screen-item {
        position: relative;
      display: flex;
      align-items: center;
      padding: 15px 60px;
      @media (max-width: 1024px) {
        padding: 15px;
        padding-left:160px;
      }

      @media(max-width:600px){
        padding-left: 15px;
      }
      //border-top: 1px solid #f4eddc;
      //border-bottom: 1px solid #f4eddc;
      font-size: 18px;
      .button-container {
        display: flex;
        justify-content: center;
        flex: 1;
        margin-left:20px;
        button {
          border-radius: 40px;
          border: none;
          padding: 15px 25px;
          margin-left: 20px;
        }
      }
    }
  }
}

.responsive-timer{
  display:none;
  @media(max-width:1000px){
    display:block;
  }
}
