* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: $light-white;
  font-family: "Metropolis";
}

span {
  font-size: 14px;
  font-family: "Gotham Rounded";
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 100;
  @media (max-width: 1440px) {
    font-size: 11px;
  }
  @media (max-width: 500px) {
    font-size: 9px;
  }
}

a {
  text-decoration: none;
  transition: 300ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

h3 {
  font-size: 120px;
  font-weight: 400;
  text-transform: uppercase;
  @media (max-width: 1440px) {
    font-size: 70px;
  }
  @media (max-width: 1024px) {
    font-size: 50px;
  }
  @media (max-width: 500px) {
    font-size: 42px;
  }
}

p {
  font-size: 18px;
  line-height: 1.7rem;
  @media (max-width: 1440px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

button {
  border-radius: 40px;
  border: none;
  padding: 10px;
  color: $light-white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0px;
}

video {
  object-fit: cover;
}

section {
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  visibility: hidden;

  .outer,
  .inner {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
  }
}

.green-dots{
  svg{
    fill :$green;

  }
}
.dots-container{
  z-index: 99999;
  position: absolute;
  right: 5%;
  top: 50%;
  display: flex;
  flex-direction: column;
  font-size: 20px;


  @media(max-width:1000px){
    display:none;
  }

  svg{
    font-size:12px;
    cursor: pointer;
  }
}

.button-left{
  transition: 300ms ease-in-out;
  transform: rotate(-5deg) !important;
  &:hover{
    opacity: 0.8;
    transform: rotate(5deg) !important;
  }
}

.button-right{
  transition: 300ms ease-in-out;
  transform: rotate(5deg) !important;
  &:hover{
    opacity: 0.8;
    transform: rotate(-5deg) !important;
  }
}

.timer{
  z-index: 9999;
  left: 5%;
  bottom: 10%;
  position:absolute;
  background-color: $light-white;
  color: $black;
  padding: 5px 15px;
  border-radius: 20px;
  letter-spacing: 0.2em;

  @media(max-width:1024px){
    left:40%;
  }

  @media(max-width:600px){
    left:28%;
  }
  p{
    color:$green;
    letter-spacing:normal;
  }
}

.preview{
  position: absolute;
  bottom: 0;
  height: 3%;
  width: 100%;

}


.second-screen, .third-section, .fifth-section, .sixth-screen-container{
  @media(max-width:1440px){
    padding-top: 60px;
  }

  @media(max-width:1000px){
    padding-top: 0px;
  }
}

.fourth-screen{
  @media(max-width:1440px){
    padding-top: 20px;
  }
  @media(max-width:1000px){
    padding-top: 0px;
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  border-radius: 50%;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

