.fourth-screen{
    background-color: $green;

    .fourth-screen-container{
        min-height: 100vh;
        padding: 0px 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        @media(max-width:500px){
            padding: 0px 20px;
        }
        
    }
    .preview{
        background-color: $blue;
    }
    
    .item-container{
        :last-child{
            border-bottom: none;
            
        }
    }
    .item{
        display: flex;
        align-items: center;
        padding: 50px 0px;
        border-bottom: 1px solid $light-white;
        @media(max-width:1440px){
            padding: 25px 0px;
        }
        @media(max-width:1024px){
            margin-bottom:60px;
        }
        @media(max-width:600px){
            margin-bottom:40px;
            justify-content: center;
            text-align: center;
        }
        .arrow-item{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 55px;
            width: 55px;
            margin-top: 30px;
            margin-left: 50px;
            border-radius: 50%;
            //padding: 40px;
            background-color: #72ABE2;
            cursor: pointer;

            @media(max-width:600px){
                display: none;
            }

            &:hover{
                svg{
                    transform: rotate(40deg);
                }
            }
            svg{
                transition: 200ms ease-in-out;
                font-size: 20px;
                transform: rotate(-40deg);

            
            }
        }
        h3{
            margin-top: 20px;

            @media(max-width:1440px){
                font-size: 80px;
            }

            @media(max-width:1024px){
                font-size: 45px;
            }
            @media(max-width:600px){
                font-size: 30px;
            }
        }
    }
}