.third-section {
  min-height: 100vh;
  background-color: $light-white;
  display: flex;
  align-items: center;

  .preview{
    background-color: $green;
  }
  @media (max-width: 968px) {
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .image-wrapper {
    @media(max-width:968px){
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .image-desktop {
      width: 800px;

      @media (max-width: 1600px) {
        width:450px;
      }

      @media (max-width: 1024px) {
        width: 400px;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }
    .image-responsive {
      display: none;
      @media (max-width: 600px) {
        display: block;
      }
    }
  }

  .info {
    margin-left: 100px;
    &-text {
      @media (max-width: 968px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
    @media (max-width: 1440px) {
      margin-left: 50px;
    }

    @media(max-width:968px){
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 20px;

    }

    @media (max-width: 600px) {
      flex: 1;
      text-align: center;
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      margin-top:20px;
      padding-right:0px;
      //justify-content: flex-end;
    }
    span {
      display:block;
      margin-bottom:10px;
      color: $green;
    }
    h3 {
      white-space: pre;
      color: $green;
      @media(max-width:1440px){
        font-size: 90px;

      }

      @media(max-width:1024px){
        font-size:70px;
      }
      @media (max-width: 600px) {
        font-size: 40px;
      }
    }
    &-contact {
      display: flex;
      align-items: center;
      margin-top: 50px;
      @media (max-width: 968px) {
        justify-content: flex-end;
      }
      @media (max-width: 600px) {
        flex-direction: column-reverse;
        margin-top: 50px;
      }
      p {
        color: $green;
        width: 50%;
        @media (max-width: 1440px) {
          font-size: 12px;
        }
        @media (max-width: 600px) {
          width: auto;
          font-size: 10px;
        }
      }
      button {
        white-space: pre;
        height: 50px;
        padding: 0px 15px;
        margin-right: 20px;
        transform: rotate(-5deg);
        text-transform: uppercase;
        font-size: 16px;
        background: #e44a3d;

        @media (max-width: 600px) {
          font-size: 14px;
          height: 40px;
          margin: 15px 0px 10px 0px;
        }
      }
    }
  }
}
