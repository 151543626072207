.fifth-section{
    height: 100%;
    flex: 1;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $gray;
    &-container{
        width: 70%;
        
    }
    h3{
        margin-top: 25px;
    }

    p{
        margin-top:30px;
    }
}