* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #F9F5ED;
  font-family: "Metropolis";
}

span {
  font-size: 14px;
  font-family: "Gotham Rounded";
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 100;
}

@media (max-width: 1440px) {
  span {
    font-size: 11px;
  }
}

@media (max-width: 500px) {
  span {
    font-size: 9px;
  }
}

a {
  text-decoration: none;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

a:hover {
  opacity: 0.8;
}

h3 {
  font-size: 120px;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 1440px) {
  h3 {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  h3 {
    font-size: 50px;
  }
}

@media (max-width: 500px) {
  h3 {
    font-size: 42px;
  }
}

p {
  font-size: 18px;
  line-height: 1.7rem;
}

@media (max-width: 1440px) {
  p {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  p {
    font-size: 12px;
  }
}

button {
  border-radius: 40px;
  border: none;
  padding: 10px;
  color: #F9F5ED;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0px;
}

video {
  -o-object-fit: cover;
     object-fit: cover;
}

section {
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  visibility: hidden;
}

section .outer,
section .inner {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

section .bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
}

.green-dots svg {
  fill: #0D6957;
}

.dots-container {
  z-index: 99999;
  position: absolute;
  right: 5%;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 20px;
}

@media (max-width: 1000px) {
  .dots-container {
    display: none;
  }
}

.dots-container svg {
  font-size: 12px;
  cursor: pointer;
}

.button-left {
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
  -webkit-transform: rotate(-5deg) !important;
          transform: rotate(-5deg) !important;
}

.button-left:hover {
  opacity: 0.8;
  -webkit-transform: rotate(5deg) !important;
          transform: rotate(5deg) !important;
}

.button-right {
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
  -webkit-transform: rotate(5deg) !important;
          transform: rotate(5deg) !important;
}

.button-right:hover {
  opacity: 0.8;
  -webkit-transform: rotate(-5deg) !important;
          transform: rotate(-5deg) !important;
}

.timer {
  z-index: 9999;
  left: 5%;
  bottom: 10%;
  position: absolute;
  background-color: #F9F5ED;
  color: #000000;
  padding: 5px 15px;
  border-radius: 20px;
  letter-spacing: 0.2em;
}

@media (max-width: 1024px) {
  .timer {
    left: 40%;
  }
}

@media (max-width: 600px) {
  .timer {
    left: 28%;
  }
}

.timer p {
  color: #0D6957;
  letter-spacing: normal;
}

.preview {
  position: absolute;
  bottom: 0;
  height: 3%;
  width: 100%;
}

@media (max-width: 1440px) {
  .second-screen, .third-section, .fifth-section, .sixth-screen-container {
    padding-top: 60px;
  }
}

@media (max-width: 1000px) {
  .second-screen, .third-section, .fifth-section, .sixth-screen-container {
    padding-top: 0px;
  }
}

@media (max-width: 1440px) {
  .fourth-screen {
    padding-top: 20px;
  }
}

@media (max-width: 1000px) {
  .fourth-screen {
    padding-top: 0px;
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  border-radius: 50%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

nav {
  position: fixed;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

nav::after {
  content: "";
  height: 1px;
  border-bottom: 1px solid white;
  position: absolute;
  width: 100%;
  -webkit-transition: width 500ms ease-in-out;
  transition: width 500ms ease-in-out;
}

nav .nav-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px 60px;
}

@media (max-width: 500px) {
  nav .nav-container svg {
    width: 100px;
  }
}

@media (max-width: 500px) {
  nav .nav-container {
    padding: 15px 10px;
  }
}

nav .hamburger-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

nav .hamburger-nav .hamburger-bar {
  width: 28px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

nav .hamburger-open :first-child {
  -webkit-transform: rotate(-45deg) translate(-2px, 4px);
          transform: rotate(-45deg) translate(-2px, 4px);
}

nav .hamburger-open :nth-child(2n) {
  opacity: 0;
}

nav .hamburger-open :nth-child(3n) {
  -webkit-transform: rotate(45deg) translate(-4px, -6px);
          transform: rotate(45deg) translate(-4px, -6px);
}

.nav-transition-border::after {
  content: "";
  height: 1px;
  width: 0% !important;
  -webkit-transition: width 500ms ease-in-out;
  transition: width 500ms ease-in-out;
}

.navbar-open {
  width: 100%;
  height: 100%;
  position: fixed;
  top: -100%;
  background-color: #72ABE2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 99;
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}

.navbar-open .contact-ul :last-child {
  border-top: none;
}

.navbar-open .home-ul :nth-child(2n) {
  border: none;
}

.navbar-open ul {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.navbar-open ul .navbar-items {
  width: 100%;
  border-bottom: 1px solid #F4EDDC;
  border-top: 1px solid #F4EDDC;
  text-transform: uppercase;
  padding: 30px 60px;
  cursor: pointer;
  font-size: 120px;
}

.navbar-open ul .navbar-items a {
  width: 100%;
  display: block;
}

.navbar-open ul .navbar-items .navbar-item-container {
  position: relative;
  display: inline;
}

.navbar-open ul .navbar-items p {
  display: inline;
  text-transform: uppercase;
  font-size: 120px;
}

@media (max-width: 1400px) {
  .navbar-open ul .navbar-items p {
    font-size: 90px;
  }
}

@media (max-width: 500px) {
  .navbar-open ul .navbar-items p {
    font-size: 60px;
  }
}

.navbar-open ul .navbar-items:hover .navbar-item-hover {
  display: block;
}

.navbar-open ul .navbar-items .navbar-item-hover {
  position: absolute;
  left: 50%;
  top: 50%;
  display: none;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
}

.navbar-open ul .navbar-items .navbar-item-hover button {
  background: #e44a3d;
  text-transform: uppercase;
}

@media (max-width: 1400px) {
  .navbar-open ul .navbar-items {
    font-size: 90px;
    padding: 15px 30px;
  }
}

@media (max-width: 500px) {
  .navbar-open ul .navbar-items {
    font-size: 60px;
    padding: 30px;
  }
}

.navbar-open .navbar-open-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .navbar-open .navbar-open-social {
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-bottom: 40px;
  }
}

.navbar-open .navbar-open-social span {
  margin-right: 50px;
}

@media (max-width: 500px) {
  .navbar-open .navbar-open-social span {
    margin-right: 0px;
  }
}

.nav-transition {
  top: 0% !important;
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}

.image {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* ie8 */
}

.fifth-section {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #163274;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #EFEDEA;
}

.fifth-section-container {
  width: 70%;
}

.fifth-section h3 {
  margin-top: 25px;
}

.fifth-section p {
  margin-top: 30px;
}

.second-screen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #000000;
  color: #F9F5ED;
  min-height: 100vh;
}

.second-screen .preview {
  background-color: #F9F5ED;
}

.second-screen-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.second-screen-container img {
  width: 800px;
}

@media (max-width: 1600px) {
  .second-screen-container img {
    width: 450px;
  }
}

@media (max-width: 1024px) {
  .second-screen-container img {
    display: none;
  }
}

@media (max-width: 500px) {
  .second-screen-container img {
    display: none;
  }
}

.second-screen-container .second-screen-title {
  padding-left: 60px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media (max-width: 1024px) {
  .second-screen-container .second-screen-title {
    padding-left: 0px;
  }
}

.second-screen-container .second-screen-title h3 {
  white-space: pre;
}

@media (max-width: 1440px) {
  .second-screen-container .second-screen-title h3 {
    font-size: 90px;
  }
}

@media (max-width: 1024px) {
  .second-screen-container .second-screen-title h3 {
    font-size: 60px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .second-screen-container .second-screen-title h3 {
    font-size: 30px;
    text-align: center;
  }
}

.second-screen-container .second-screen-title span {
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .second-screen-container .second-screen-title span {
    margin-bottom: 20px;
    display: block;
    text-align: center;
  }
}

.second-screen .second-screen-items {
  margin: 50px 0px;
}

.second-screen .second-screen-items :nth-child(2) {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.second-screen .second-screen-items :first-child div::after {
  content: '';
  height: 1px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  background-color: white;
}

@media (max-width: 1024px) {
  .second-screen .second-screen-items :first-child div::after {
    width: 100%;
  }
}

.second-screen .second-screen-items :first-child div::before {
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  width: 100.8%;
  left: 0;
  background-color: white;
}

@media (max-width: 1440px) {
  .second-screen .second-screen-items :first-child div::before {
    width: 100.4%;
  }
}

@media (max-width: 1024px) {
  .second-screen .second-screen-items :first-child div::before {
    width: 100%;
  }
}

.second-screen .second-screen-items :nth-child(2) div::before {
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  width: 102.5%;
  left: 0;
  background-color: white;
}

@media (max-width: 1440px) {
  .second-screen .second-screen-items :nth-child(2) div::before {
    width: 103.5%;
  }
}

@media (max-width: 1024px) {
  .second-screen .second-screen-items :nth-child(2) div::before {
    width: 100%;
  }
}

.second-screen .second-screen-items :nth-child(3) div::before {
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  width: 106%;
  left: 0;
  background-color: white;
}

@media (max-width: 1440px) {
  .second-screen .second-screen-items :nth-child(3) div::before {
    width: 110.1%;
  }
}

@media (max-width: 1024px) {
  .second-screen .second-screen-items :nth-child(3) div::before {
    width: 100%;
  }
}

.second-screen .second-screen-items .second-screen-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 60px;
  font-size: 18px;
}

@media (max-width: 1024px) {
  .second-screen .second-screen-items .second-screen-item {
    padding: 15px;
    padding-left: 160px;
  }
}

@media (max-width: 600px) {
  .second-screen .second-screen-items .second-screen-item {
    padding-left: 15px;
  }
}

.second-screen .second-screen-items .second-screen-item .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 20px;
}

.second-screen .second-screen-items .second-screen-item .button-container button {
  border-radius: 40px;
  border: none;
  padding: 15px 25px;
  margin-left: 20px;
}

.responsive-timer {
  display: none;
}

@media (max-width: 1000px) {
  .responsive-timer {
    display: block;
  }
}

.first-screen .preview {
  background-color: #000000;
}

.first-screen .first-screen-opacity {
  position: absolute;
  text-align: center;
  -webkit-animation: opacity 2s ease-in-out;
          animation: opacity 2s ease-in-out;
  width: 100%;
  height: 100%;
  background: rgba(114, 172, 226, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.first-screen .first-screen-opacity h3 {
  white-space: pre;
  color: white;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .first-screen .first-screen-opacity h3 {
    font-size: 25px;
  }
}

@-webkit-keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #775045;
}

@media (max-width: 1440px) {
  .footer {
    min-height: auto;
  }
}

.footer-group-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: 30px;
}

@media (max-width: 1440px) {
  .footer-group-container {
    margin-top: 15px;
  }
}

.footer-group-title {
  margin-bottom: 20px;
}

.footer-group-title span {
  font-size: 14px;
}

@media (max-width: 1440px) {
  .footer-group-title span {
    font-size: 12px;
  }
}

.footer-group-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}

.footer-group-item span {
  font-size: 12px;
}

@media (max-width: 1440px) {
  .footer-group-item span {
    font-size: 9px;
  }
}

@media (max-width: 500px) {
  .footer-group-item span {
    font-size: 7px;
  }
}

.footer-group-item svg {
  margin-right: 10px;
}

@media (max-width: 500px) {
  .footer-group-item svg {
    font-size: 9px;
  }
}

.footer-copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 12%;
  border-top: 1px solid #EFEDEA;
}

@media (max-width: 500px) {
  .footer-copy {
    height: auto;
    padding: 10px 6%;
  }
}

.footer-copy span {
  font-size: 12px;
  text-transform: none;
  line-height: 10px;
  letter-spacing: normal;
}

@media (max-width: 500px) {
  .footer-copy span {
    font-size: 9px;
  }
}

.third-section {
  min-height: 100vh;
  background-color: #F9F5ED;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.third-section .preview {
  background-color: #0D6957;
}

@media (max-width: 968px) {
  .third-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media (max-width: 968px) {
  .third-section .image-wrapper {
    width: 100%;
  }
}

.third-section .image-wrapper img {
  width: 100%;
  height: 100%;
}

.third-section .image-wrapper .image-desktop {
  width: 800px;
}

@media (max-width: 1600px) {
  .third-section .image-wrapper .image-desktop {
    width: 450px;
  }
}

@media (max-width: 1024px) {
  .third-section .image-wrapper .image-desktop {
    width: 400px;
  }
}

@media (max-width: 600px) {
  .third-section .image-wrapper .image-desktop {
    display: none;
  }
}

.third-section .image-wrapper .image-responsive {
  display: none;
}

@media (max-width: 600px) {
  .third-section .image-wrapper .image-responsive {
    display: block;
  }
}

.third-section .info {
  margin-left: 100px;
}

@media (max-width: 968px) {
  .third-section .info-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

@media (max-width: 1440px) {
  .third-section .info {
    margin-left: 50px;
  }
}

@media (max-width: 968px) {
  .third-section .info {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 20px;
  }
}

@media (max-width: 600px) {
  .third-section .info {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: center;
    margin-left: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 20px;
    padding-right: 0px;
  }
}

.third-section .info span {
  display: block;
  margin-bottom: 10px;
  color: #0D6957;
}

.third-section .info h3 {
  white-space: pre;
  color: #0D6957;
}

@media (max-width: 1440px) {
  .third-section .info h3 {
    font-size: 90px;
  }
}

@media (max-width: 1024px) {
  .third-section .info h3 {
    font-size: 70px;
  }
}

@media (max-width: 600px) {
  .third-section .info h3 {
    font-size: 40px;
  }
}

.third-section .info-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 50px;
}

@media (max-width: 968px) {
  .third-section .info-contact {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

@media (max-width: 600px) {
  .third-section .info-contact {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin-top: 50px;
  }
}

.third-section .info-contact p {
  color: #0D6957;
  width: 50%;
}

@media (max-width: 1440px) {
  .third-section .info-contact p {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .third-section .info-contact p {
    width: auto;
    font-size: 10px;
  }
}

.third-section .info-contact button {
  white-space: pre;
  height: 50px;
  padding: 0px 15px;
  margin-right: 20px;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  text-transform: uppercase;
  font-size: 16px;
  background: #e44a3d;
}

@media (max-width: 600px) {
  .third-section .info-contact button {
    font-size: 14px;
    height: 40px;
    margin: 15px 0px 10px 0px;
  }
}

.fourth-screen {
  background-color: #0D6957;
}

.fourth-screen .fourth-screen-container {
  min-height: 100vh;
  padding: 0px 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 500px) {
  .fourth-screen .fourth-screen-container {
    padding: 0px 20px;
  }
}

.fourth-screen .preview {
  background-color: #163274;
}

.fourth-screen .item-container :last-child {
  border-bottom: none;
}

.fourth-screen .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 0px;
  border-bottom: 1px solid #F9F5ED;
}

@media (max-width: 1440px) {
  .fourth-screen .item {
    padding: 25px 0px;
  }
}

@media (max-width: 1024px) {
  .fourth-screen .item {
    margin-bottom: 60px;
  }
}

@media (max-width: 600px) {
  .fourth-screen .item {
    margin-bottom: 40px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}

.fourth-screen .item .arrow-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 55px;
  width: 55px;
  margin-top: 30px;
  margin-left: 50px;
  border-radius: 50%;
  background-color: #72ABE2;
  cursor: pointer;
}

@media (max-width: 600px) {
  .fourth-screen .item .arrow-item {
    display: none;
  }
}

.fourth-screen .item .arrow-item:hover svg {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
}

.fourth-screen .item .arrow-item svg {
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  font-size: 20px;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
}

.fourth-screen .item h3 {
  margin-top: 20px;
}

@media (max-width: 1440px) {
  .fourth-screen .item h3 {
    font-size: 80px;
  }
}

@media (max-width: 1024px) {
  .fourth-screen .item h3 {
    font-size: 45px;
  }
}

@media (max-width: 600px) {
  .fourth-screen .item h3 {
    font-size: 30px;
  }
}

.sixth-screen-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 60px;
  background-color: #0D6957;
}

@media (max-width: 800px) {
  .sixth-screen-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.sixth-screen-container .sixth-screen-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media (max-width: 800px) {
  .sixth-screen-container .sixth-screen-info {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    width: 100%;
  }
}

.sixth-screen-container .sixth-screen-info h3 {
  margin-top: 10px;
}

.sixth-screen-container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media (max-width: 800px) {
  .sixth-screen-container form {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    width: 100%;
    margin-top: 40px;
  }
}

.sixth-screen-container form label {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 14px;
}

.sixth-screen-container form input {
  background: none;
  border: none;
  border-bottom: 1px solid #72ABE2;
  outline: none;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.sixth-screen-container form button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 25px;
  background-color: #72ABE2;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}

.sixth-screen-container .message-succeeded {
  margin-top: 20px;
  color: #0D6957;
}

.sixth-screen-container .message-failed {
  margin-top: 20px;
  color: #dc3545;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-SemiBold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/gotham-rounded/GothamRounded-Book.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/gotham-rounded/GothamRounded-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
