.sixth-screen-container{
    display: flex;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px;
    background-color: $green;

    @media(max-width:800px){
        flex-direction:column;
        justify-content: center;
    }

    .sixth-screen-info{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        @media(max-width:800px){
            flex: none;
            width: 100%;
        }

        h3{
            margin-top: 10px;
        }
    }
    form{
        display: flex;
        flex-direction: column;
        flex: 1;

        @media(max-width:800px){
            flex: none;
            width: 100%;
            margin-top: 40px;
        }
        label{
            margin-left: 20px;
            margin-bottom: 10px;
            font-size: 14px;
        }

        input{
            background:none;
            border: none;
            border-bottom: 1px solid $light-blue;
            outline: none;
            padding: 5px 10px;
            margin-bottom: 20px;
        }

        button{
            width: fit-content;
            padding: 10px 25px;
            background-color: $light-blue;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            transform: rotate(-5deg);
        }
    }
    .message-succeeded{
        margin-top:20px;
        color:$green;
    }
    .message-failed{
        margin-top:20px;
        color:#dc3545;
    }
}