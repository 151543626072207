nav{
    position: fixed;
    z-index: 999;
    //border-bottom: 1px solid white;
    width: 100%;
    &::after{
      content: "";
      height: 1px;
      border-bottom: 1px solid white;
      position: absolute;
      width: 100%;
      transition: width 500ms ease-in-out;
    }
    .nav-container{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 60px;
      

      svg{
        @media(max-width:500px){
          width: 100px;
        }
      }

      @media(max-width:500px){
        padding: 15px 10px;
      }
    }
    
    transition: all 300ms ease-in-out;
    .hamburger-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
        .hamburger-bar {
          width: 28px;
          height: 2px;
          background-color: white;
          margin: 2px 0;
          transition: 300ms ease-in-out;
        }
      }
      

  .hamburger-open {
    :first-child {
      transform: rotate(-45deg) translate(-2px, 4px);
    }
    :nth-child(2n) {
      opacity: 0;
    }
    :nth-child(3n){
      transform: rotate(45deg) translate(-4px, -6px);

    }
  }
}

.nav-transition-border{
  &::after{
    content: "";
    height: 1px;
    width: 0% !important;
    transition: width 500ms ease-in-out;
  }
}

.navbar-open{
  width: 100%;
  height: 100%;
  position: fixed;
  top:-100%;
  background-color: $light-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  transition: 500ms ease-in-out;

  .contact-ul{

    

    :last-child{
      border-top:none;
    }
  }

  .home-ul{

    :nth-child(2n){
      border:none;
    }
  }
  ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;



    .navbar-items{
      width: 100%;
      border-bottom: 1px solid #F4EDDC;
      border-top: 1px solid #F4EDDC;
      text-transform: uppercase;
      padding: 30px 60px;
      cursor: pointer;
      font-size: 120px;
      a{
        width:100%;
        display:block;
      }
      .navbar-item-container{
        position:relative;
        display:inline;
      }

      p{
        display:inline;
        text-transform: uppercase;
        font-size: 120px;
        @media(max-width:1400px){
          font-size: 90px;
        }
        @media(max-width:500px){
          font-size: 60px;
        }
        //padding: 30px 60px;
      }
      &:hover .navbar-item-hover {
        display:block;
      }

      .navbar-item-hover{
        position:absolute;
        left:50%;
        top:50%;
        display:none;
        transform: translate(-50%, -70%);

        button{
          background:#e44a3d;
          text-transform: uppercase;

        }
      }
      @media(max-width:1400px){
        font-size: 90px;
        padding:15px 30px;
      }

      @media(max-width:500px){
        font-size: 60px;
        padding: 30px;
      }
    }
  }

  .navbar-open-social{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    @media(max-width:500px){
      justify-content: space-around;
      margin-bottom: 40px;
    }
    span{
      //font-size: 11px !important;
      margin-right: 50px;
      @media(max-width:500px){
        margin-right: 0px;
      }
    }
  }
  
}

.nav-transition{
  top: 0% !important;
  transition: 500ms ease-in-out;
}


.image{
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}
